<template>
  <div class="product-info">
    <a-descriptions>
      <div slot="title" class="title-area">
        <div>
          <strong>{{ data.name }}&nbsp;</strong>
          <a-icon
            type="edit"
            v-if="hasAction('edit-product-info-admin')&&data.originType === 'SCP'"
            @click="handleEdit()"
          />
          <a-tag style="margin-left:10px">{{data.originType === 'SCP'?'分享起源':'企名片'}}</a-tag>
        </div>
        <a-button type="primary" style="" @click="handleUpdate" v-if="hasAction('update-external-data')&&data.originType === 'QMP'">
            更新项目详情
        </a-button>
      </div>
      <a-descriptions-item label="项目名称">
        {{ data.name }}
      </a-descriptions-item>
      <a-descriptions-item label="所属公司">
        <router-link
          :to="`/company/${data.companyId}/product-manage`"
          v-if="hasRouter('company-list')"
        >
          {{ data.companyName }}
        </router-link>
        <span v-else>{{ data.companyName }}</span>
      </a-descriptions-item>
      <a-descriptions-item label="业务">
        {{ data.business }}
      </a-descriptions-item>
      <a-descriptions-item label="国家地区">{{
        data.country
      }}</a-descriptions-item>
      <a-descriptions-item label="估值级别">{{
        data.estimateLevel
      }}</a-descriptions-item>
      <a-descriptions-item label="所在地">
        {{ data.location }}
      </a-descriptions-item>
      <a-descriptions-item label="是否需要融资">{{
        data.needFlag | flagFilter
      }}</a-descriptions-item>
      <!-- <a-descriptions-item label="数据来源">{{ data.originType }}</a-descriptions-item> -->
      <a-descriptions-item label="最新融资轮次">
        {{ data.round }}
      </a-descriptions-item>
      <a-descriptions-item label="行业">
        {{ data.sector }}
      </a-descriptions-item>
      <a-descriptions-item label="成立时间">
        {{ data.setupDate }}
      </a-descriptions-item>
      <a-descriptions-item label="相似行业">{{
        data.similarLabel
      }}</a-descriptions-item>
      <a-descriptions-item label="行业领域（参考）">{{
        data.subIndustry
      }}</a-descriptions-item>
      <a-descriptions-item label="细分行业">
        {{ data.subSector }}
      </a-descriptions-item>
      <a-descriptions-item label="市值">{{
        data.valuationsMoney
      }}</a-descriptions-item>

      <a-descriptions-item label="上市时间">{{
        data.valuationsTime
      }}</a-descriptions-item>

      <a-descriptions-item label="融资总额（万元）">
        {{ data.totalRaisedFund }}
      </a-descriptions-item>
      <a-descriptions-item :span="2" label="官网地址">
        <a :href="data.officialWebsite" target="_blank">{{
          data.officialWebsite
        }}</a>
      </a-descriptions-item>

      <a-descriptions-item label="标签" :span="3">
        <a-tag
          v-for="(item, index) in data.labels
            ? data.labels.replace('，', ',').split(',')
            : []"
          :key="index"
          >{{ item }}</a-tag
        >
      </a-descriptions-item>
      <a-descriptions-item label="介绍" :span="3">
        {{ data.introduction }}
      </a-descriptions-item>
    </a-descriptions>
  </div>
</template>

<script>
import { Descriptions } from "ant-design-vue";
import { getProductInfo, updateProductFromQMP } from "@/api/product";
import ProductForm from "./components/ProductForm";

export default {
  name: "productBaseInfo",
  components: {
    ADescriptions: Descriptions,
    ADescriptionsItem: Descriptions.Item,
  },
  data() {
    return {
      data: {},
      productId: this.$route.params.productId
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      getProductInfo({
        productId: this.productId,
      }).then((res) => {
        if (res.code === 0) {
          this.data = res.data;
        }
      });
    },
    handleEdit() {
      this.$dialog(
        ProductForm,
        {
          record: this.data,
          on: {
            ok: () => {
              this.loadData();
            },
            cancel() {},
            close() {},
          },
        },
        {
          title: "更新",
          width: 700,
          height: 500,
          centered: true,
          maskClosable: false,
        }
      );
    },
    handleUpdate() {
      this.$dialog(
        {
          render() {
            return (<div>此更新功能需要调用企名片项目数据接口，会消耗一定资源且会覆盖原有的项目信息，请认真核查后再决定是否更新公司项目数据。</div>)
          },
        },
        {
          on:{
            ok:() => {
              updateProductFromQMP({ productId: this.productId }).then((res) => {
                if(res.code === 0) {
                  this.$message.success('更新企名片数据成功！')
                  this.loadData()
                }
              })
            }
          }
        },
        {
          title:"提示",
          okText:'确定更新',
          width: 400,
          height: 200,
        }
      )
    }
  },
  filters: {
    flagFilter: (value) => {
      return value ? "是" : "否";
    },
  },
};
</script>

<style lang="less" scoped>
.product-info {
  background-color: white;
  padding: 20px;
  .input-style {
    background-color: white;
    color: rgba(0, 0, 0, 0.65);
  }
  .no-border {
    border: none;
  }
}

.title-area {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
</style>
