<template>
  <a-form :form="form">
    <a-form-item label="项目名称" :labelCol="labelCol" :wrapperCol="wrapperCol">
      <a-input
        v-decorator="['name', { initialValue: record.name }]"
        placeholder="请输入项目名称"
      />
    </a-form-item>
    <a-form-item label="业务" :labelCol="labelCol" :wrapperCol="wrapperCol">
      <a-input
        v-decorator="['business', { initialValue: record.business }]"
        placeholder="请输入业务"
      />
    </a-form-item>
    <a-form-item label="所在地" :labelCol="labelCol" :wrapperCol="wrapperCol">
      <a-input
        v-decorator="['location', { initialValue: record.location }]"
        placeholder="请输入所在地"
      />
    </a-form-item>
    <a-form-item label="最新融资轮次" :labelCol="labelCol" :wrapperCol="wrapperCol">
      <a-input
        v-decorator="['round', { initialValue: record.round }]"
        placeholder="请输入最新融资轮次"
      />
    </a-form-item>
    <a-form-item label="行业" :labelCol="labelCol" :wrapperCol="wrapperCol">
      <a-input
        v-decorator="['sector', { initialValue: record.sector }]"
        placeholder="请输入行业"
      />
    </a-form-item>
    <a-form-item label="成立时间" :labelCol="labelCol" :wrapperCol="wrapperCol">
      <a-date-picker
        v-decorator="['setupDate', { initialValue: record.setupDate?moment(record.setupDate) :null }]"
        placeholder="请输入成立时间"
      />
    </a-form-item>
    <a-form-item label="细分行业" :labelCol="labelCol" :wrapperCol="wrapperCol">
      <a-input
        v-decorator="['subSector', { initialValue: record.subSector }]"
        placeholder="请输入细分行业"
      />
    </a-form-item>
    <a-form-item
      label="融资总额（万元）"
      :labelCol="labelCol"
      :wrapperCol="wrapperCol"
    >
      <a-input
        type="number"
        v-decorator="[
          'totalRaisedFund',
          { initialValue: record.totalRaisedFund },
        ]"
        placeholder="请输入融资总额（万元）"
      />
    </a-form-item>
    <a-form-item label="官网地址" :labelCol="labelCol" :wrapperCol="wrapperCol">
      <a-input
        v-decorator="[
          'officialWebsite',
          { initialValue: record.officialWebsite },
        ]"
        placeholder="请输入官网地址"
      />
    </a-form-item>
    <a-form-item label="标签" :labelCol="labelCol" :wrapperCol="wrapperCol">
      <a-select
        mode="tags"
        :default-value="tags"
        :token-separators="[',']"
        @change="handleChange"
      >
      </a-select>
    </a-form-item>
    <a-form-item label="介绍" :labelCol="labelCol" :wrapperCol="wrapperCol">
      <a-text-area
        v-decorator="['introduction', { initialValue: record.introduction }]"
        placeholder="请输入介绍"
        :rows="6"
      />
    </a-form-item>
  </a-form>
</template>

<script>
import { Input } from "ant-design-vue";
import { updateProduct } from "@/api/product";
import moment from "moment";

export default {
  name: "TaskForm",
  props: {
    record: {
      type: Object,
      default: null,
    },
  },
  components: {
    ATextArea: Input.TextArea,
  },
  data() {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 },
      },
      form: this.$form.createForm(this),
      selectedTags: [],
    };
  },
  computed: {
    tags() {
      return this.record.labels
        ? this.record.labels.replaceAll("，", ",").split(",")
        : [];
    },
  },
  created() {
    this.selectedTags = this.tags
  },
  methods: {
    moment,
    onOk() {
      return new Promise((resolve) => {
        this.form.validateFields((err, values) => {
          if (!err) {
            let product = values;
            let formData = {
              id: this.record.id,
              labels: this.selectedTags.join(","),
              companyId: this.record.companyId,
              business: product.business,
              introduction: product.introduction,
              location: product.location,
              name: product.name,
              officialWebsite: product.officialWebsite,
              round: product.round,
              sector: product.sector,
              setupDate: moment(product.setupDate)
                .utc(480)
                .format("YYYY-MM-DD"),
              subSector: product.subSector,
              totalRaisedFund: product.totalRaisedFund,
            };
            updateProduct(formData).then(() => {
              this.$message.success("更新成功");
              resolve(true);
            });
          }
        });
      });
    },
    onCancel() {
      return new Promise((resolve) => {
        resolve(true);
      });
    },
    handleChange(item) {
      this.selectedTags = item;
    },
  },
};
</script>

<style scoped>
/* tile uploaded pictures */
.upload-list-inline >>> .ant-upload-list-item {
  float: left;
  width: 200px;
  margin-right: 8px;
}
.upload-list-inline >>> .ant-upload-animate-enter {
  animation-name: uploadAnimateInlineIn;
}
.upload-list-inline >>> .ant-upload-animate-leave {
  animation-name: uploadAnimateInlineOut;
}
</style>
